import { createReducer } from '@reduxjs/toolkit'
import { setStats, setVaultsList } from './actions'

export interface StatsState {
  stats: any
  vaultsList: any
}

const initialState: StatsState = {
  stats: {},
  vaultsList: {}
}

export default createReducer<StatsState>(initialState, builder =>
  builder
    .addCase(setStats, (state, { payload: { stats } }) => {
      state.stats = stats
    })
    .addCase(setVaultsList, (state, { payload: { vaultsList } }) => {
      state.vaultsList = vaultsList
    })
)
