import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { RowCenter } from '../components/Row'

export const LayoutWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const LayoutContainer = styled.div`
  flex: 1;
  height: 100%;
`

export const LayoutHeader = styled.div<{ bg: boolean }>`
  position: fixed;
  width: 100%;
  height: 60px;
  background: ${({ bg }) => (bg ? '#ffffff' : 'transparent')};
  z-index: 99;
  box-shadow: ${({ bg }) => (bg ? '0px 0px 10px rgba(108, 108, 108, 0.16)' : 'none')};
`
export const MainContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`
export const HeaderWrap = styled(MainContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .mobile-menu {
    display: none;
    justify-content: space-between;
    align-items: center;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      display: flex;
    `};
  }
  .menu {
    cursor: pointer;
    img {
      width: 24px;
    }
    ${({ theme }) => theme.mediaWidth.upToSmall`
      display: block;
    `};
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding:0 14px
  `};
`

export const StyledLogoLink = styled(NavLink)``

export const HeaderLogo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  .logo {
    height: 48px;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

export const HeadLang = styled.div<{ bg?: boolean }>`
  margin-left: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  position: relative;
  cursor: pointer;
  &:hover {
    .lang-list {
      display: flex;
    }
    .lang-arrow {
      display: block;
    }
    .lang-active img {
      transform: rotate(180deg);
    }
  }
  .lang-active {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    height: 60px;
    color: ${({ theme, bg }) => (bg ? theme.primary : theme.white)};
    img {
      width: 14px;
      margin-left: 4px;
    }
  }
  .lang-arrow {
    display: none;
    position: absolute;
    top: 60px;
    right: 22px;
    z-index: 1;
    width: 8px;
    height: 8px;
    background: transparent;
    border-style: solid;
    border-width: 4px;
    transform: rotate(45deg);
    border-color: #fff transparent transparent #fff;
    box-shadow: -2px -2px 5px rgb(0 0 0 / 6%);
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 0;
  `};
`
export const LangList = styled.div`
  width: 106px;
  display: none;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 64px;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px 0px 10px rgba(108, 108, 108, 0.16);
  padding: 24px;
  z-index: 10;
  border-radius: 6px;
  color: ${({ theme }) => theme.primary};
`
export const LangItem = styled.div<{ active: boolean }>`
  font-size: 14px;
  line-height: 28px;
  cursor: pointer;
  color: ${({ theme, active }) => (active ? theme.mainColor : theme.primary)};
  &:hover {
    color: ${({ theme }) => theme.mainColor};
  }
`

const activeClassName = 'active'
export const HeaderLinks = styled.div<{ bg?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    color: ${({ theme, bg }) => (bg ? theme.primary : theme.white)};
    :hover {
      color: ${({ theme, bg }) => (bg ? theme.mainColor : theme.white)};
    }
  }
  .${activeClassName} {
    color: ${({ theme, bg }) => (bg ? theme.mainColor : theme.white)};
  }

  .${activeClassName}::after {
    position: absolute;
    content: '';
    height: 2px;
    background: ${({ theme, bg }) => (bg ? theme.mainColor : theme.white)};
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 2px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 0;
    left: auto;
    right: 0;
    width: 168px;
    border: none;
    bottom: 0;
    height: 100vh;
    position: fixed;
    background-color: #fff;
    z-index: 9;

    flex-direction: column;
    justify-content: start;
  `};
`

export const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})<{ bg?: boolean }>`
  font-size: 16px;
  line-height: 60px;
  padding: 0 4px;
  margin: 0 18px;
  font-weight: 400;
  position: relative;
`

export const StyledNavLinkText = styled.div<{ bg: boolean }>``

export const HeaderMobileContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.27);
  z-index: 9;
  .bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.27);
    z-index: 9;
  }
`

export const LayoutFooter = styled.div`
  position: relative;
  width: 100%;
  background: ${({ theme }) => theme.mainBg};
`
export const FooterContainer = styled(MainContainer)`
  padding: 70px 0 56px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 70px 50px 56px;
  `}
`
export const FooterText = styled(RowCenter)`
  font-size: 14px;
  color: ${({ theme }) => theme.white};
  text-align: center;
`
export const FooterLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 54px;
`
export const FooterLinkItem = styled(NavLink)`
  margin: 0 16px;
  img {
    width: 28px;
  }
`
