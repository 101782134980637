import styled from 'styled-components'

export const DownloadWrap = styled.div`
  position: relative;
`
export const ContainerWrap = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(315deg, #5a35f4 0%, #965fff 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`

export const LogoWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 45px;
  img {
    widht: 70px;
    height: 70px;
  }
`
export const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  color: #ffffff;
  .title {
    font-size: 16px;
    font-family: Rubik;
    font-weight: 500;
    margin-bottom: 12px;
  }
  .text {
    font-size: 16px;
    font-family: Rubik;
    font-weight: 500;
  }
`

export const DownloadBtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6vh;
  .btn {
    width: 45%;
    line-height: 6vh;
    background: #ffffff;
    border-radius: 6px;
    font-size: 14px;
    font-family: Rubik;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    color: ${({ theme }) => theme.primary};
    img{
      width:22px;
      vertical-align: middle;
      margin-right: 6px;
  }
`

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }
`

export const DownloadBg = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  img {
    width: 100%;
    height: 100vh;
  }
`
