import styled from 'styled-components'

export const AppBody = styled.div`
  position: relative;
  width: 100%;
`
export const AppSection = styled.div`
  margin-top: 60px;
`

export const SectionContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 50px 14px;
  `};
`

export const MainTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 24px;
`
export const TextContainer = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 24px;
  line-height: 20px;
`
export const MinorTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
`
