import React from 'react'

import { LayoutFooter, FooterContainer, FooterLink, FooterLinkItem, FooterText } from '../styles'
import { RowBetween } from '../../components/Row'

export default function Footer() {
  return (
    <LayoutFooter>
      <FooterContainer>
        <FooterLink>
          <RowBetween>
            <FooterLinkItem to={'/'}>
              <img src={require('../../assets/images/githubIcon.png')} alt="" srcSet="" />
            </FooterLinkItem>
            <FooterLinkItem to={'/'}>
              <img src={require('../../assets/images/twitterIcon.png')} alt="" srcSet="" />
            </FooterLinkItem>
            <FooterLinkItem to={'/'}>
              <img src={require('../../assets/images/discordIcon.png')} alt="" srcSet="" />
            </FooterLinkItem>
            <FooterLinkItem to={'/'}>
              <img src={require('../../assets/images/helpIcon.png')} alt="" srcSet="" />
            </FooterLinkItem>
          </RowBetween>
        </FooterLink>
        <FooterText>© Copyright 2016~2021. TYCO FINTECH TECHNOLOGY PTY LTD</FooterText>
      </FooterContainer>
    </LayoutFooter>
  )
}
