import styled from 'styled-components'

import headBg from '../../assets/images/head_bg.png'
import mheadBg from '../../assets/images/head_bg_m.png'

export const AppBody = styled.div`
  position: relative;
  width: 100%;
`
export const AppHeadWrap = styled.div`
  background-repeat: no-repeat;
  background: url(${headBg});
  background-size: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    background: url(${mheadBg});
    margin-top: 60px;
  `}
`
export const MainContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

export const HeadContainer = styled(MainContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  .head-img {
    width: 488px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    padding: 56px 14px 0;
    .head-img {
      width: 100%;
      margin-top: 0;
    }
  `}
`

export const HeadTitle = styled.div`
  color: ${({ theme }) => theme.white};
  .title {
    font-size: 50px;
    font-weight: 500;
  }
  .text {
    font-size: 28px;
    font-weight: 400;
    margin: 20px 0 44px;
  }
  .download {
    display: flex;
    align-items: center;
    .download-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .item {
      width: 144px;
      height: 46px;
      background: ${({ theme }) => theme.white};
      border-radius: 6px;
      color: ${({ theme }) => theme.primary};
      margin-right: 16px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
      }
    }
  }

  .download-qr {
    position: relative;
    .icon {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }
    &:hover {
      .qrcode {
        display: block;
      }
    }
    .qrcode {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 20px;
      width: 132px;
      text-align: center;
      box-shadow: 0 4px 40px 0 rgb(0 77 255 / 10%);
      border-radius: 8px;
      padding: 10px 0;
      font-size: 14px;
      background: #fff;
      img {
        width: 110px;
      }
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    .title {
      font-size: 24px;
      text-align: center;
    }
    .text {
      font-size: 14px;
      text-align: center;
    }
    .download {
      .download-link{
        width: 100%;
      }
      .item{
        width: 124px;
        height: 38px;
        font-size: 14px;
        margin-right: 32px;
        img{
          width: 22px;
          height: 22px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .download-qr{
      display:none;
    }
  `}
`

export const AppSectionWrap = styled.div<{ bg?: boolean }>`
  background: ${({ theme, bg }) => (bg ? theme.mainBg : '#fafbfd')};
`

export const SectionContainer = styled(MainContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    padding: 38px 14px;
    img {
      order: 2;
      flex-basis: auto;
      width: 100%;
    }
  `}
`

export const SectionText = styled.div<{ bg?: boolean }>`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme, bg }) => (bg ? theme.white : theme.primary)};
  .title {
    font-size: 30px;
    font-weight: bold;
  }
  .text {
    margin: 30px 0 12px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    order: 1;
    flex-basis: auto;
    font-size: 14px;
    .title {
      font-size: 22px;
    }
  `}
`
