import React from 'react'

import Footer from '../../layout/components/Footer'
import Header from '../../layout/components/Header'

import { AppBody, AppSection, SectionContainer, MainTitle, TextContainer, MinorTitle } from './styles'

export default function Terms() {
  return (
    <AppBody>
      <Header />
      <AppSection>
        <SectionContainer>
          <MainTitle>Terms of Service</MainTitle>
          <TextContainer>
            <p>Dear users,</p>
            <p>
              Welcome! The "Kanga Wallet Service Agreement" (hereinafter referred to as "this Agreement") is signed by
              TYCO FINTECH TECHNOLOGY PTY LTD (referred to as the "we,""our" or "us") and users (hereinafter referred to
              as "you" or "User"). There is a contractual legal effect for you and our company.
            </p>
            <p>
              We hereby reminds you to read the "Kanga Wallet Service Agreement" and the following relevant agreements,
              especially the terms of the "Limitation of Liability & Disclaimer of Warranties" before using Kanga Wallet
              ("Kanga Wallet" or "The Software"), visiting our website and accepting our services. Please ensure you
              fully understand the terms of this Agreement and consider risks yourself.
            </p>
          </TextContainer>
          <MinorTitle>HOW YOU ACCEPT THIS POLICY</MinorTitle>
          <TextContainer>
            <p>
              By downloading Kanga Wallet, or accessing our website, you are deemed to have fully read and accepted all
              the terms of this Agreement and the related "Privacy Policy". This Agreement is effective immediately and
              is binding on both parties.
            </p>
            <p>
              We reserve the right to change this Agreement at any time. Any changes to the Agreement, as posted on
              Kanga Wallet or our website, will automatically take effect without notice. After the announcing the terms
              of the revised agreement, if you do not accept the revised terms, please stop using Kanga Wallet, our
              website and other services immediately. And your continued use will be deemed to accept the revised
              agreement.
            </p>
            <p>
              If you are under the age of 18, or have no civil capacity or restricted capacity, use the Kanga Wallet
              under the direction of your parent or guardian.
            </p>
          </TextContainer>
          <MinorTitle>DEFINITION</MinorTitle>
          <TextContainer>
            <p>1.Kanga Wallet:</p>
            <p>Kanga Wallet provides self-hosted wallet services for Ethereum-based blockchains.</p>
            <p>2.Eligibility:</p>
            <p>2.1 You must be a natural person with full civil capacity.</p>
            <p>
              2.2 If you are using the Kanga Wallet service for minors under the age of 18, you will need to use the
              Kanga Wallet under the direction of your parent or guardian. Kanga Wallet reserves the right to ask you
              and your parent or guardian to be responsible for any consequences of the use of Kanga Wallet or a person
              with limited capacity to conduct a transaction beyond the scope of his or her civil rights or capacity.
            </p>
            <p>
              2.3 By using Kanga Wallet, you are warranted to comply with all of the eligibility requirements listed in
              these Terms. However, we may still refuse to allow certain people to access or use our products and
              services, and we reserve the right to change eligibility criteria at any time.
            </p>
            <p>3.Create or Import a wallet</p>
            <p>
              Use Kanga Wallet to confirm the process of fulfilling this agreement and creating or importing a wallet.
            </p>
            <p>4.Wallet password</p>
            <p>
              During the process of creating a Kanga Wallet, the software operation interface prompts you to fill in a
              password that is used to encrypt and protect the private key. The wallet password is not stored on your
              mobile device or on our server. Once you lose it, you need to reset the new password with the aid of a
              mnemonic.
            </p>
            <p>5.Notification</p>
            <p>
              The information prompts involved in the Kanga Wallet software operation interface are recommended to
              follow the prompts.
            </p>
            <p>6.Private Key</p>
            <p>Consisting of 256-bit random characters, it is the core of your own and use of Token.</p>
            <p>7.Public Key</p>
            <p>
              The public key is generated by the private key by means of cryptographic principles, and is used to
              generate a blockchain digital wallet address, which is the public collection address.
            </p>
            <p>8.Mnemonic</p>
            <p>
              We follow the blockchain BIP39 industry standard. The 24 ordered English words generated through your own
              devices (mobile phones, tablets, etc.) randomly are your mnemonics. The mnemonic is an easy-to-record
              representation of the private key, which is convenient for users to backup and keep.
            </p>
            <p>9.Personal Information</p>
            <p>
              Various information recorded electronically or otherwise that can identify the user's personal identity,
              either alone or in combination with other information, including but not limited to the name of the
              natural person, date of birth, ID number, personal biometric information, address, telephone number, bank
              card number , email address, wallet address, mobile device information, operation history, transaction
              history, etc., but not the user's wallet password, private key, and mnemonic.
            </p>
          </TextContainer>
          <MinorTitle>THE SERVICES</MinorTitle>
          <TextContainer>
            <p>1.Create or Import a wallet</p>
            <p>
              For Tokens supported by Kanga Wallet, you can use Kanga Wallet to generate a new wallet or import the
              generated wallet via mnemonics. If you accidentally forget your password, you can use the mnemonic to
              import the created wallet and reset your password. The process of creating mnemonics belongs to the local
              behavior. It can be operated in the case of network disconnection, it has nothing to do with our server.
              Therefore, we do not have your mnemonic and cannot retrieve mnemonics for you.
            </p>
            <p>2. Send, Recieve token</p>
            <p>
              You can use the payment and collection function of Kanga Wallet to manage Token, that is, use the private
              key to electronically sign and modify the ledger of the relevant blockchain. This "payment" involves a
              valid record of the transaction in the distributed system of the relevant blockchain (rather than actually
              delivering or transferring digital tokens on the Kanga Wallet).
            </p>
            <p>3. Delegate, Redelegate, Undelegate and Withdraw rewards</p>
            <p>
              You can view the profile of the trusted Validator, the voting power, the public key, the commission rate,
              and can initiate a transaction to the specified Validator. The delegation transactions involves a valid
              record of the transaction in the distributed system of the relevant blockchain (rather than actually
              delivering or transferring digital tokens at Kanga Wallet).
            </p>
            <p>4. Manage Token</p>
            <p>You can use Kanga Wallet to add, keep, or remove Tokens supported by Kanga Wallet.</p>
            <p>5. Transaction History</p>
            <p>
              We will copy all or part of your transaction history through the blockchain system. However, the
              transaction records are subject to the records of the blockchain system.
            </p>
            <p>6. Discontinuance of Services</p>
            <p>
              We can't suspend or cancel the payment transaction for you based on the "irrevocable" nature of the
              blockchain system transaction. And under certain circumstances, we can suspend or limit the operation of a
              user on the Kanga Wallet software.
            </p>
            <p>7. Other services that we believe are necessary.</p>
            <p>You acknowledge the following when you accept the above services provided by us:</p>
            <p>
              (1) We provides distributed services due to the distributed nature of the blockchain. We cannot control
              the blockchain network, which is greatly different from banking financial institutions. You should fully
              understand and agree that we cannot provide the following services: Store your wallet password (the
              password set when the you creates/imports the wallet), private key, and mnemonic; Retrieve the your wallet
              password, private key, and mnemonic; Freeze the wallet; Report the loss; Restore the wallet; Cancel or
              Roll back Transaction.
            </p>
            <p>
              (2) You should keep your own mobile devices with Kanga Wallet, backup Kanga Wallet, backup wallet
              password, and mnemonic. If the user loses the mobile device, deletes and does not back up the Kanga
              Wallet, the wallet is stolen or you forget the wallet password and the mnemonic, we are unable to restore
              the wallet or retrieve the wallet password, private key and mnemonic; If the user mishandles the
              transaction (for example, the wrong payment address), we cannot cancel the transaction.
            </p>
            <p>
              (3) The Token management service that we and Kanga Wallet can provide does not include all Tokens. Do not
              operate any Token that Kanga Wallet does not support through it.
            </p>
            <p>
              (4) Kanga Wallet is only a Token management tool, not an exchange or trading platform. Although this
              Agreement will refer to “transactions” many times, it refers to the send, delegate, undelegate,
              redelegate, withdraw rewards operations performed by the user using Kanga Wallet, which is essentially
              different from the “transaction” conducted on the exchange or trading platform. We do not own, control or
              operate these networks, and therefore cannot guarantee that any transactions submitted through our
              services will be confirmed and processed because of the network characteristic of blockchain. With Kanga
              Wallet, you acknowledge and agree that the transaction you submitted may not be completed by the
              blockchain network or may be significantly delayed.
            </p>
            <p>
              (5) Agreed to this Agreement, you are deemed to have understood and proficient in the blockchain assets
              and wallet related matters. If you still do not understand of such matters, you should immediately
              terminate the use of our products and services, you have the responsibility and obligation to keep the
              assets in your account safe, and we are not responsible for providing training or implied with legal
              liability.
            </p>
            <p>
              (6) We have the right to provide differentiated services at different times, in different regions or from
              different network sources for reasonable reasons. We do not guarantee that users at all times, all regions
              or all network sources will always get a platform response. We will not be liable for any loss or loss of
              service caused by the user.
            </p>
          </TextContainer>

          <MinorTitle>RIGHTS AND OBLIGATIONS</MinorTitle>
          <TextContainer>
            <p>1. Create or import a wallet</p>
            <p>1.1 Create or import a wallet</p>
            <p>
              You can create or import a wallet via Kanga Wallet on your mobile device, set the wallet's password and
              other information, and you can initiate transactions via the Kanga Wallet app.
            </p>
            <p>1.2 Authentication</p>
            <p>
              In accordance with relevant laws, regulations and policies, specified users should complete the relevant
              authentication in accordance with the instructions of Kanga Wallet when using the services provided by
              Kanga Wallet, and ask you to submit, including but not limited to, your name, ID number, mobile number,
              Personal information such as bank card number information. Otherwise, the specific user will not be able
              to use the service, and the loss caused by the delay of the specific user is at your own risk.
            </p>
            <p>
              1.3 We may develop different software versions for different terminal devices. You should choose to
              download the appropriate version for installation according to actual needs. If you obtain the software or
              the installation program with the same name as the software from a third party that is not legally
              authorized, we cannot guarantee the normal use of the software or the security of the software, and the
              loss is at your own risk.
            </p>
            <p>
              1.4 After the new version of the software is released, the old version of the software may not be
              available. We do not guarantee the security of the old software, continue to provide usability and provide
              corresponding customer service. Please check and update the latest version at any time.
            </p>
            <p>2. Use of Kanga Wallet</p>
            <p>
              2.1 You should keep your mobile device, wallet password, private key, mnemonic, etc. We are not
              responsible for keeping this information for you. You are solely responsible for any risks, liabilities,
              losses, and expenses incurred as a result of your loss of mobile devices, active or passive disclosure,
              forgotten wallet passwords, private keys, mnemonics, or attacks, fraud, etc.
            </p>
            <p>
              2.2 Follow the information prompts. You should follow the information that Kanga Wallet makes to you and
              follow the information prompts. Otherwise, all risks, liabilities, losses, expenses, etc. caused by you
              shall be borne by you.
            </p>
            <p>
              2.3 Please complete the authentication actively. When we believe that your trading behavior or trading
              situation is abnormal, or that your identity information is suspect, or if Kanga Wallet believes that you
              should check your identity documents or other necessary documents, please cooperate with Kanga Wallet to
              check your valid identity document or other necessary documents to complete the relevant authentication in
              a timely manner.
            </p>
            <p>2.4 Transactions</p>
            <p>
              (a)You are aware that your daily cumulative limit and number of transactions may be due to the
              country/region where you are using the payment service, regulatory requirements, transaction purposes,
              Risk Wallet risk control, etc.
            </p>
            <p>
              (b) You understand the "irrevocable" attribute based on blockchain operation. When you use the Kanga
              Wallet to initiate a transaction, you should bear the consequences of your operation errors (including but
              not limited to the wrong collection address and your own choice of node server).
            </p>
            <p>
              (c) You are aware that when using the Kanga Wallet service, the following conditions may result in a
              "transaction failure" or "package timeout": The wallet balance is insufficient;Blockchain failed to
              execute; Exceeding the transaction amount required by the regulatory authorities, Kanga Wallet or laws and
              regulations; Technical problems of network and equipment; The abandoned transaction caused by blockchain
              network congestion and faults, etc. Your address or counterparty address is identified as a special
              address, such as a high-risk address, an exchange address, and so on.
            </p>
            <p>2.5 Legitimacy</p>
            <p>When using Kanga Wallet, you should follow the relevant laws and regulations and national policies.</p>
            <p>2.6 Fees</p>
            <p>
              Kanga Wallet does not charge you any fees for any of its Services. However, we reserve the right to do so
              in the future and, in such case, we will stipulate or publish the rules separately and will prompt you
              before you use any of the paid services.
            </p>
          </TextContainer>

          <MinorTitle>ASSUMPTION OF RISK</MinorTitle>
          <TextContainer>
            <p>
              1.Due to the fact that the laws, regulations and policies in the blockchain field are not yet sound, you
              acknowledge and agree that Token in this field may have significant risks such as failing to cash and
              technical instability. And Token's price volatility is much higher than other financial assets. We caution
              you to rationally choose to hold or dispose of any Token based on your financial situation and risk
              appetite.
            </p>
            <p>
              2.When using the Kanga Wallet, if you or your counterparty fails to follow the operation tips and rules in
              this agreement or related website and APP description page, we do not guarantee that the operation will be
              completed smoothly, and we will not bear liability for the damage.In the event that the above scenario
              occurs and the blockchain asset has been credited to your or your counterpart's Kanga Wallet or third
              party‘s wallet, you understand the “irreversible” nature of the blockchain operation and the irreversible
              transaction. You and your counterparts bear the corresponding risk. We strongly recommend to carefully
              review your transaction details before attempting to transfer your Token.
            </p>
            <p>
              3.Please ensure the other party you decide to deal with is a fully civil capacity person, you bear all the
              risks associated with it.
            </p>
            <p>
              4.In the transaction process, if there is a similar information such as “transaction failure”, “package
              timeout”, etc., you should reconfirm through the official route of the relevant blockchain system or other
              blockchain query tools. Avoid repeated payments; otherwise, all losses and expenses caused by this shall
              be borne by you.
            </p>
            <p>
              5.You understand that when you create or import a wallet on Kanga Wallet, your private key, mnemonic, etc.
              are only stored on the current mobile device and are not stored on the Kanga Wallet or our server. You can
              replace your mobile device via importing wallet, etc., according to the instructions provided by Kanga
              Wallet. However, if you do not save or back up your wallet password, mnemonic, etc. and your mobile device
              is lost, your Token will be lost. We will not be able to retrieve it for you. You will be responsible for
              the loss. If you leak when exporting, saving or backing up your wallet password, mnemonic, etc., or if the
              device or server that saves or backs up the above information is hacked or controlled, your Token will be
              lost. We cannot retrieve it for you, and you will be responsible for the loss.
            </p>
            <p>
              6.We recommend that you make a secure backup of your wallet's password, mnemonic, etc. when creating or
              importing your wallet. We draw your attention, please do not use the following backup methods:
              screenshots, e-mail, notepad, SMS, WeChat, QQ and other electronic backup methods. We recommend that you
              copy information such as mnemonics on a paper notebook, and you can also keep your electronic data in the
              password manager.
            </p>
            <p>
              7.We recommend that you use Kanga Wallet in a secure network environment to ensure that your mobile device
              is not jailbroken or rooted to avoid potential security risks.
            </p>
            <p>
              8.Please be alert to non-official fraud when using our service. We encourage you to inform us as soon as
              you find such behavior.
            </p>
          </TextContainer>

          <MinorTitle>SERVICE CHANGE, INTERRUPTION, TERMINATION</MinorTitle>
          <TextContainer>
            <p>
              1.You understand and agree that TYCO FINTECH TECHNOLOGY PTY LTD may temporarily provide some of its
              service functions, suspend some service functions or open new service functions in the future to ensure
              that its own business operations rights. When any feature is reduced, increased or changed, you will still
              agree to the terms of this Agreement as long as you still use the services provided by us.
            </p>
            <p>
              2.You understand and agree that we requires the maintenance of platforms that provide network services
              (such as Internet sites, mobile networks, etc.) and related devices on a regular or irregular basis, and
              that Internet connectivity is affected by global network stability, technical conditions, user location
              and network used, power supply, government regulation, computer viruses, hacking attacks, etc. The company
              does not require any liability for this. No prior notice is required except in special circumstances.
            </p>
            <p>3.You understand that we will suspend the provision of services in following situations:</p>
            <p>
              3.1 Interruption of business due to technical reasons such as equipment, blockchain system maintenance,
              upgrades, failures and communication interruptions;
            </p>
            <p>
              3.2 Due to the "force majeure" , our system cannot provide services or we reasonably believes that there
              will be greater risks in continuing to provide services; "force majeure" events include but are not
              limited to information network equipment maintenance, information network connection failure, fault of
              computers, communications or other systems, power failures, strikes, labor disputes, riots, uprisings,
              riots, lack of productivity or means of production, fires, floods, storms, explosions, wars, government
              actions, orders of the judiciary, and etc.
            </p>
            <p>3.3 Other situations where we cannot control or reasonably foreseen.</p>
            <p>4.We may suspend or terminate some or all of your functions unilaterally in the following situations:</p>
            <p>4.1 User’s death;</p>
            <p>4.2 Stealing someone else's wallet information or mobile device;</p>
            <p>4.3 Provide false information when filling out personal information;</p>
            <p>4.4 Rejecting the mandatory update operation we initiated to improve our Products or Services;</p>
            <p>4.5 Use Kanga Wallet for illegal or criminal activities;</p>
            <p>4.6 Prevent other users from using it normally;</p>
            <p>4.7 Falsely claiming as our staff or management staff;</p>
            <p>4.8 Attack, invade, alter, or other ways to threaten the proper functioning of our computer systems;</p>
            <p>4.9 Promote spam ads with Kanga Wallet;</p>
            <p>4.10 Spread rumors and damage our goodwill;</p>
            <p>
              4.11 Violations, other violations of this Agreement, and circumstances in which we reasonably believe that
              the function should be suspended.
            </p>
            <p>
              5.When the service relationship between you and us is changed, interrupted, or terminated, you still have
              the right to export information such as your wallet within a reasonable time.
            </p>
          </TextContainer>

          <MinorTitle>Help Center</MinorTitle>
          <TextContainer>
            <p>Get in touch with our team to know more about TYCO.</p>
            <p>Ask any question you might have about the product or your requirements.</p>
            <p>We’d be happy to answer them. Shoot us an email:</p>
            <p>Email: tycofintech@outlook.com</p>
          </TextContainer>

          <MinorTitle>COMMITMENT TO LEGAL USE OF OUR SERVICES</MinorTitle>
          <TextContainer>
            <p>1.may not use Kanga Wallet for any illegal purposes or use our Service in any illegal way.</p>
            <p>2.You may not use Kanga Wallet for illegal or criminal conduct, including but not limited to:</p>
            <p>
              2.1 Engage in any criminal activity, including but not limited to money laundering, illegal fund raising,
              etc.;
            </p>
            <p>
              2.2 Intervene or attempt to interfere with any user or any other means to access our services by using any
              automated program, software, engine, web crawler, web analytics tool, data mining tool, or similar tool to
              access our service, collect or process content we provide.
            </p>
            <p>2.3 Provide gambling information or induce others to participate in gambling in any way;</p>
            <p>
              2.4 Use of our services in any form infringes on the company's commercial interests, including but not
              limited to the release of commercials that are not licensed by the company;
            </p>
            <p>2.5 Invade other people's Kanga Wallet to steal Token;</p>
            <p>
              2.6 Conduct a transaction that is inconsistent with the transaction content claimed by the counterparty,
              or an untrue transaction;
            </p>
            <p>2.7 Engage in any infringement or possible violation of our service systems and data;</p>
            <p>
              2.8 Infringe the patent, copyright, trademark, reputation or any other legal rights of any other third
              party;
            </p>
            <p>2.9 Other violations and actions that we have justified for improper reasons.</p>
            <p>
              3.You understand and agree that if you breach the relevant laws (including but not limited to regulations
              relates tp customs and tax) or the provisions of this Agreement, you should compensate us, including
              reasonable attorney fees, if we suffer any loss, claim by any third party or any administrative department
              Penalties.
            </p>
            <p>
              4.You promise to pay our service fees (if any) on time, otherwise we reserves the right to suspend or stop
              the services provided to you.
            </p>
          </TextContainer>

          <MinorTitle>PRIVACY POLICY</MinorTitle>
          <TextContainer>
            <p>
              We attach great importance to the protection of users' privacy. For related privacy protection policies,
              please refer to the “Kanga Privacy Notice” that we update from time to time.
            </p>
          </TextContainer>

          <MinorTitle>LIMITATION OF LIABILITY&DISCLAIMER OF WARRANTIES</MinorTitle>
          <TextContainer>
            <p>
              1.TYCO FINTECH TECHNOLOGY PTY LTD is only responsible for the obligations set forth in this Agreement.
            </p>
            <p>
              2.You acknowledge and agree that, to the fullest extent permitted by law, we may only provide services in
              accordance with the current state of the technology. We are not responsible for the failure to provide
              services due to the following reasons:
            </p>
            <p>2.1 Our system is down for maintenance or upgrades;</p>
            <p>2.2 Force majeure causes such as typhoons, earthquakes, floods, lightning or terrorist attacks;</p>
            <p>
              2.3 The hardware and software of your mobile device and the communication and power supply are faulty;
            </p>
            <p>2.4 You use our services improperly or not authorized or approved by us;</p>
            <p>
              2.5 Causes due to viruses, Trojans, malicious program attacks, network congestion, system instability,
              system or equipment failure, communication failures, power failures, banks, or government actions, etc;
            </p>
            <p>2.6 Any other reason not caused by us.</p>
            <p>3.We are not responsible for the following situations:</p>
            <p>
              3.1 Token losses caused by the user losing the mobile device, deleting and not backing up the Kanga
              Wallet, deleting and not backing up the wallet, the wallet being stolen or forgetting the wallet password
              and the mnemonic;
            </p>
            <p>
              3.2 Token losses caused by the user disclosing the wallet password, private key, mnemonic, or borrowing,
              transferring or authorizing others to use their mobile device or Kanga Wallet, or downloading the Kanga
              Wallet app through unofficial channels or other unsafe ways.
            </p>
            <p>
              3.3 Token losses due to users' misoperation (including but not limited to the wrong address you imported);
            </p>
            <p>
              3.4 Token losses caused by misoperation because that the user do not understand the nature of the
              blockchain technology;
            </p>
            <p>
              3.5 Due to the time lag, the instability of the blockchain system, etc., the transaction records of our
              copy users on the blockchain are deviated.
            </p>
            <p>
              4.We may provide services to you and your counterparties at the same time. You agree to expressly waive
              any actual or potential conflicts of interest in our conduct, and cannot claim that we have flaws in the
              provision of services. This does not add to our responsibility or duty of care.
            </p>
            <p>5.We do not provide guarantees in the following forms:</p>
            <p>5.1 Our services will meet all your needs;</p>
            <p>
              5.2 Any technology, products, services, and information you obtain through our services will meet your
              expectations;
            </p>
            <p>
              5.3 The parties that you trade with on Kanga Wallet will promptly perform their obligations in the
              transaction agreement with you.
            </p>
            <p>
              6.You understand that Kanga Wallet is only used as a tool for users to manage Tokens and display
              transaction information. We do not provide legal, tax or investment advice. You should seek advice from
              professionals in the legal, tax, and investment fields, and we are not responsible for any investment
              losses, data loss, etc. that you suffer from using our services.
            </p>
            <p>
              7.You understand that we may change our user access criteria from time to time, limiting the scope and
              manner of providing services to a particular group, as required by relevant Hong Kong SAR policies and
              regulations.
            </p>
          </TextContainer>

          <MinorTitle>COMPLETE AGREEMENT</MinorTitle>
          <TextContainer>
            <p>
              1.This Agreement consists of the "Kanga Wallet Service Agreement", the "Kanga Privacy Notice" and the
              rules published by us from time to time, including the contents of the Help Center.
            </p>
            <p>
              2.Part of this agreement is deemed to be a violation or invalid by a court of competent jurisdiction but
              does not affect the validity of other content.
            </p>
            <p>
              3.Any translation of this Agreement is provided solely for the convenience of the User and is not intended
              to modify the terms of this Agreement. In the event of a conflict between the English version and the
              non-English version of this Agreement, the English version shall prevail.
            </p>
          </TextContainer>

          <MinorTitle>INTELLECTUAL PROPERTY</MinorTitle>
          <TextContainer>
            <p>
              Kanga Wallet is an application that TYCO FINTECH TECHNOLOGY PTY LTD developed and own intellectual
              property. The intellectual property rights of any content displayed in Kanga Wallet, including this
              Agreement, announcements, articles, videos, audio, images, files, information, materials, trademarks or
              logos, are owned by us or third party rights holders. Users can only use the Kanga Wallet application and
              its contents for the purpose of holding and managing tokens. No one may use, modify, reverse compile,
              reproduce, publicly transmit, alter, distribute, release or publicly publish such applications and content
              without the prior written consent of us or third party rights holders.
            </p>
          </TextContainer>
        </SectionContainer>
      </AppSection>

      <Footer />
    </AppBody>
  )
}
