import React, { useMemo } from 'react'
import { ThemeProvider as StyledComponentsThemeProvider, createGlobalStyle, css, DefaultTheme } from 'styled-components'

import { Colors } from './styled'

const MEDIA_WIDTHS = {
  upToExtraSmall: 576,
  upToSmall: 852,
  upToMedium: 968,
  upToLarge: 1080
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

export function colors(): Colors {
  return {
    white: '#FFFFFF',

    primary: '#292929',
    mainColor: '#5A35F4',

    bodyBg: '#FFFFFF',
    mainBg: '#433482'
  }
}

export function theme(): DefaultTheme {
  return {
    ...colors(),
    // media queries
    mediaWidth: mediaWidthTemplates
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  // const darkMode = useIsDarkMode()
  // const themeObject = useMemo(() => theme(darkMode), [darkMode])
  const themeObject = useMemo(() => theme(), [])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

export const FixedGlobalStyle = createGlobalStyle`
html, input, textarea, button {
  font-display: fallback;
}
@supports (font-variation-settings: normal) {
 
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-track-color: transparent;
  scrollbar-width: none;
  &::-webkit-scrollbar{
    display: none;
  }
  &::-webkit-scrollbar-thumb{
    border-radius: 6px;
    background:rgba(0,0,0,0.2);
  }
  &::-webkit-scrollbar-track{
    border-radius: 6px;
    background :rgba(0,0,0,0.2);
  }
}

* {
  box-sizing: border-box;
  margin:0;
}

button {
  user-select: none;
}

html {
  font-size: 16px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-feature-settings: 'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on;
  
}
a{
  text-decoration: none;
}
`

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.primary};
  background-color: ${({ theme }) => theme.bodyBg};
}

body {
  min-height: 100vh;
  background-position: 0 -30vh;
  background-repeat: no-repeat;
  background-size: 100%;
}
`
