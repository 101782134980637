import React from 'react'

import Header from '../../layout/components/Header'
import Footer from '../../layout/components/Footer'

import { AppBody, AppSection, SectionContainer, MainTitle, TextContainer } from './styles'

export default function Help() {
  return (
    <AppBody>
      <Header />
      <AppSection>
        <SectionContainer>
          <MainTitle>Help Center</MainTitle>
          <TextContainer>
            <p>Get in touch with our team to know more about TYCO.</p>
            <p>Ask any question you might have about the product or your requirements.</p>
            <p>We’d be happy to answer them. Shoot us an email:</p>
            <p>Email: tycofintech@outlook.com</p>
          </TextContainer>
        </SectionContainer>
      </AppSection>

      <Footer />
    </AppBody>
  )
}
