export interface FooterLinkState {
  id: string
  name: string
  link: string
  logo: string
}

export const footerLinks: FooterLinkState[] = []

export const downLink = {
  andriod: 'https://download.tycofintech.com/Kanga_snapshot.apk',
  ios: 'https://apps.apple.com/us/app/tyco-kanga-wallet/id1575505681',
  google: 'https://play.google.com/store/apps/details?id=com.cuckoo.wallet'
}
