import React from 'react'

import Header from '../../layout/components/Header'
import Footer from '../../layout/components/Footer'

import { AppBody, AppSection, SectionContainer, MainTitle, TextContainer, MinorTitle } from './styles'

export default function Privacy() {
  return (
    <AppBody>
      <Header />
      <AppSection>
        <SectionContainer>
          <MainTitle>Privacy Policy</MainTitle>
          <TextContainer>
            <p>Dear Users,</p>
            <p>
              Kanga (or referred to as "we" or “us”) attaches great importance to the privacy protection of you. When
              you use the products or services that we provide, we will collect, use, share and protect your personal
              information in accordance with this privacy policy (the “Policy”).
            </p>
            <p>
              Before using this product ("Kanga") and the services we provide, please read and fully understand the full
              content of this policy. By using Kanga , which includes browsing, accessing our website and/or any product
              or service offered by us, you consent to the collection, use, sharing and protection of your personal
              information in accordance with this policy.
            </p>
            <p>
              This policy can be updated by at any time. Once the updated policy is announced, it will replace the
              original policy. If you do not accept the revised terms, please stop using the products and services
              provided by us immediately. If you continue to use our services, we will deem it as accepting the revised
              policy.
            </p>
          </TextContainer>
          <MinorTitle>OVERVIEW</MinorTitle>
          <TextContainer>
            Your privacy is important to us. At Kanga, we follow a few fundamental principles: We don’t ask you for
            personally identifiable information (defined below). That being said, your contact information, such as your
            phone number, social media handle, or email address (depending on how you contact us), may be collected when
            you communicate with us or if you report a bug or other error related to Kanga. We don’t share your
            information with third parties except to deliver you our Services and products, comply with the law, make
            Kanga better, protect our rights, or effectuate a business transfer. We’re not a huge, faceless corporation.
            We’re just developers trying to deliver an incredible product. If you have any questions or concerns about
            this policy, please reach out to us at tycofintech@outlook.com. HOW YOU ACCEPT THIS POLICY By using Kanga,
            including downloading one of our mobile applications, visiting our website, you agree to the use,
            disclosure, and procedures outlined in this Privacy Policy.
          </TextContainer>
          <MinorTitle>WHAT PERSONAL INFORMATION DO WE COLLECT FROM OUR USERS?</MinorTitle>
          <TextContainer>
            The information we collect from you falls into two categories: (i) personally identifiable information
            (i.e., data that could potentially identify you as an individual) (“Personal Information”), and (ii)
            non-personally identifiable information (i.e., information that cannot be used to identify who you are)
            (“Non-Personal Information”). This Privacy Policy covers both categories and will tell you how we might
            collect and use each type. We do our best not to collect any Personal Information from Kanga users. Further,
            we may collect some Personal Information from you when you communicate with us, including your contact
            information, such as your phone number, social media handle, or email address (depending on how you reach
            out). Like other online services, we also collect a variety of Non-Personal Information, including:
            Information you create through the Kanga’s website or mobile applications, including public addresses.
            Various analytics data, such as: (i) the IP address of the computer you use to access Kanga; (ii) the type
            of browser software you are using; (iii) the operating system you are using; (iv) the date and time you
            access or use Kanga; (v) the website address, if any, that linked you to Kanga; (vi) the website address, if
            any, you leave our website and travel to; and (vii) other non-personally identifiable traffic data.
          </TextContainer>
          <MinorTitle>HOW WE COLLECT INFORMATION</MinorTitle>
          <TextContainer>
            When You Contact Us. We may collect certain information if you choose to contact us, if you use our Services
            or if you report a bug or other error with Kanga. This may include contact information such as your name,
            email address, phone number, and public address. We, or companies that provide services on our behalf, may
            also collect certain Non-Personal Information from you, such as your locally hosted public (a “Wallet”)
            addresses.
          </TextContainer>

          <MinorTitle>INFORMATION WE AUTOMATICALLY COLLECT</MinorTitle>
          <TextContainer>
            Users who visit our website or use our application may have their device’s IP address logged for the purpose
            of generating anonymous statistics or troubleshooting the performance of our web servers. Your IP address
            will not be used to track or identify you, but may be used to determine your geographic location in order to
            determine which of our services you are presented with.
          </TextContainer>

          <MinorTitle>THIRD PARTY SERVICES</MinorTitle>
          <TextContainer>
            Certain features on Kanga rely on various third-party products and services (collectively “Third Party
            Services”), such as the Ethereum network, Google Analytics, Apple’s application platform. These services may
            collect certain Personal Information, such as your public addresses. Kanga uses Google Analytics, a web
            analytics service provided by Google, Inc. (“Google”). Google uses cookies to help the website analyze how
            users use our website. The information generated by the cookie about your use of our website (including your
            IP address) will be transmitted to and stored by Google on servers in the United States. Google will use
            this information for the purpose of evaluating your use of the website, compiling reports on website
            activity for website operators and providing other services relating to website activity and internet usage.
            Google may also transfer this information to third parties where required to do so by law, or where such
            third parties process the information on Google’s behalf. Google will not associate your IP address with any
            other data held by Google. You may choose to accept the cookies by selecting the appropriate settings on
            your browser if you do this you may not be able to use the full functionality of our website. By using our
            website, you consent to the processing of data about you by Google in the manner and for the purposes set
            out above. Please note that your use of these Third Party Services is governed by their respective Terms of
            Service and Privacy Policies. We use and disclose any collected information in accordance with our own
            Privacy Policy.
          </TextContainer>

          <MinorTitle>HOW WE USE THE INFORMATION WE GATHER</MinorTitle>
          <TextContainer>
            We primarily use the limited information we collect to enhance Kanga. Except if we sell all or a portion of
            our business, or as otherwise described below, we do not rent, trade, or sell your Personal Information. Use
            of Information to Provide Kanga to You Some ways we may use your Personal Information are to: Contact you
            when necessary; Respond to your comments, questions, or issues related to bugs or errors with Kanga; Provide
            you with additional information; Send you information and marketing materials about services and products
            available through Kanga, using push notifications or other means; Train our team members; or Other internal
            business purposes. Aggregated Personal Data and Non-Personal Information We may share or disclose aggregated
            Personal Data or Non-Personal Information with service providers or with other persons we conduct business
            with, including but not limited potential third-parties for the purpose of showcasing the performance of the
            company. These service providers and other persons may also share with us aggregated Non-Personal
            Information that they have independently developed or acquired. Additionally, we may combine aggregate
            information from the pixel tags, web beacons, and cookies with similar data we collect from other visitors
            to help us improve our Services. When doing so, we do our best to ensure that the any aggregated information
            cannot be linked back to you.
          </TextContainer>

          <MinorTitle>AGENTS OR THIRD PARTY PARTNERS</MinorTitle>
          <TextContainer>
            We may provide your Personal Information to our employees, contractors, agents, service providers, and
            designees (“Agents”) to enable them to perform certain services for us exclusively, including: Improvement
            of website-related services and features; and Perform maintenance services. Business Transfers We may choose
            to buy or sell assets. In these types of transactions, customer information is typically one of the business
            assets that would be transferred. Also, if we (or our assets) are acquired, or if we go out of business,
            enter bankruptcy, or go through some other change of control, your Personal Information could be one of the
            assets transferred to or acquired by a third party. By accepting this Privacy Policy, as outlined above, you
            consent to any such transfer.
          </TextContainer>

          <MinorTitle>PROTECTION OF US AND OTHERS</MinorTitle>
          <TextContainer>
            We reserve the right to access, read, preserve, and disclose any information that we reasonably believe is
            necessary to: comply with the law or a court order; cooperate with law enforcement; enforce or apply our
            Terms of Use and other agreements; or protect the rights, property, or safety of Kanga, our employees, our
            users, or others.
          </TextContainer>

          <MinorTitle>WHAT PERSONAL INFORMATION CAN I ACCESS OR CHANGE?</MinorTitle>
          <TextContainer>
            You can request access to the information we have collected from you. You can do this by contacting us at
            tycofintech@outlook.com. We will make sure to provide you with a copy of the data we process about you. To
            comply with your request, we may ask you to verify your identity. We will fulfill your request by sending
            your copy electronically. For any subsequent access request, we may charge you with an administrative fee.
            If you believe that the information we have collected is incorrect, you are welcome to contact us so we can
            update it and keep your data accurate. Any data that is no longer needed for purposes specified in the “How
            We Use the Information We Gather” section will be deleted after ninety (90) days. addresses created through
            the Kanga application cannot be deleted from the Ethereum blockchain, therefore we are unable to delete this
            personal information. If at any point you wish for Kanga to delete information about you, you may contact us
            at tycofintech@outlook.com.
          </TextContainer>

          <MinorTitle>DATA RETENTION</MinorTitle>
          <TextContainer>
            If you delete your or addresses from the Kanga mobile application, uninstall Kanga mobile applications from
            your device, or request that your information be deleted, we still may retain some information that you have
            provided to us to maintain Kanga or to comply with relevant laws.
          </TextContainer>

          <MinorTitle>DATA SECURITY</MinorTitle>
          <TextContainer>
            We are committed to making sure your information is protected and have selected third-party vendors which
            use the Ethereum network, that help keep your Personal Information safe. Unfortunately, we do not control
            these third parties and therefore cannot guarantee complete security. We employ several physical and
            electronic safeguards to keep your information safe, including encrypted user passwords, two factor
            verification and authentication on passwords where possible, and securing all connections with industry
            standard transport layer security. Even with all these precautions, we cannot fully guarantee against the
            access, disclosure, alteration, or deletion of data through events, including but not limited to hardware or
            software failure or unauthorized use. Any information that you provide to us is done so entirely at your own
            risk.
          </TextContainer>

          <MinorTitle>CHILDREN</MinorTitle>
          <TextContainer>
            We are especially sensitive about children’s information. Our Services are not targeted towards children,
            and we don’t knowingly collect information from children under the age of 13. If you are a parent or legal
            guardian of a minor child, we will treat any information that you provide us while using Kanga on behalf of
            your minor child as Personal Information as otherwise provided in this Privacy Policy. If you have questions
            concerning our information practices with respect to children, or if you learn that a child under the age of
            13 has used Kanga, created a user account, or provided us with personal information, please email us at
            tycofintech@outlook.com
          </TextContainer>

          <MinorTitle>ONLINE TRACKING AND HOW WE RESPOND TO DO NOT TRACK SIGNALS</MinorTitle>
          <TextContainer>
            Online tracking is the collection of data about an individual’s Internet activity used to deliver targeted
            advertisements and for other purposes. Some web browsers (including Safari, Internet Explorer, Firefox, and
            Chrome) incorporate a “Do Not Track” (DNT) or similar feature that signals to websites that a visitor does
            not want to have his/her online activity and behavior tracked. If an online service elects to respond to a
            particular DNT signal, the service may refrain from collecting certain personal information about the
            browser’s user. Not all browsers offer a DNT option and there is currently no industry consensus as to what
            constitutes a DNT signal. For these reasons, many website operators, including Kanga, do not take action to
            respond to DNT signals. For more information about DNT signals, visit http://www.tyco.link/.
          </TextContainer>

          <MinorTitle>CHANGES AND UPDATES TO PRIVACY POLICY</MinorTitle>
          <TextContainer>
            We reserve the right to update and revise this privacy policy at any time. We occasionally review this
            Privacy Policy to make sure it complies with applicable laws and conforms to changes in our business. We may
            need to update this Privacy Policy, and we reserve the right to do so at any time. If we do revise this
            Privacy Policy, we will update the “Effective Date” at the bottom of this page so that you can tell if it
            has changed since your last visit and will do our best to notify you. Please review this Privacy Policy
            regularly to ensure that you are aware of its terms. Any use of Kanga after an amendment to our Privacy
            Policy constitutes your acceptance to the revised or amended agreement.
          </TextContainer>

          <MinorTitle>DISCLAIMER</MinorTitle>
          <TextContainer>
            After you link to the third-party-developed DApps from Kanga, the privacy policies of the
            third-party-developed DApps will apply. The collection and use of your Personal Information by the
            third-party-developed DApps will be controlled neither by Kanga, nor by this Policy. You shall carefully
            select and use the third-party-developed DApps and protect your Personal Information. Kanga shall not be
            held liable for the privacy protection obligation of any third-party-developed DApps.You acknowledge and
            accept that, to the maximum extent permitted by applicable law,Kanga will adopt measures as reasonable as
            possible to protect your Personal Information under current techniques on an “as is”, “as available” and
            “with all faults” basis, to avoid the disclosure, tampering or damage of information. since Kanga transfers
            data wirelessly, Kanga makes no guarantee on the privacy and security of wireless internet data
            transferring.
          </TextContainer>

          <MinorTitle>QUESTIONS</MinorTitle>
          <TextContainer>
            <p>We’d be happy to answer them. Shoot us an email:</p>
            <p>Email: tycofintech@outlook.com,Thanks for reading our Privacy Policy!</p>
          </TextContainer>
        </SectionContainer>
      </AppSection>

      <Footer />
    </AppBody>
  )
}
