import styled from 'styled-components'

const Row = styled.div<{ align?: string }>`
  display: flex;
  align-items: ${({ align }) => (align ? align : 'center')};
`

export const RowBetween = styled(Row)`
  justify-content: space-between;
`

export const RowCenter = styled(Row)`
  justify-content: center;
`
