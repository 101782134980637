import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import home from './home/reducer'

const store = configureStore({
  reducer: {
    home
  },
  middleware: [...getDefaultMiddleware({ thunk: false })]
})

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
