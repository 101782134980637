import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

import styled from 'styled-components'

import Home from './Home'
import Privacy from './Privacy'
import Terms from './Terms'
import Help from './Help'
import Download from './Download'
import './i18n'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  justify-content: center;
`

export default function App() {
  return (
    <Suspense fallback={null}>
      <AppWrapper className="App">
        <BodyWrapper>
          <Switch>
            <Route exact strict path="/" component={Home} />
            <Route exact strict path="/privacy" component={Privacy} />
            <Route exact strict path="/terms" component={Terms} />
            <Route exact strict path="/help" component={Help} />
            <Route exact strict path="/download" component={Download} />
          </Switch>
        </BodyWrapper>
      </AppWrapper>
    </Suspense>
  )
}
