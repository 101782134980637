import React, { useCallback, useMemo, useState } from 'react'
import { downLink } from '../../config/links'
import { DownloadWrap, ContainerWrap, LogoWrap, TitleWrap, DownloadBtn, Container, DownloadBg } from './styles'

export default function Download() {
  const [showBg, setShowBg] = useState<boolean>(false)
  const ua = window.navigator.userAgent.toLowerCase()
  document.title = 'Kanga official APP download'
  const isWx = useMemo(() => {
    if (ua.indexOf('micromessenger') !== -1) {
      return true // 是微信端
    } else {
      return false
    }
  }, [ua])

  const isIos = useMemo(() => {
    if (ua.match(/iphone|ipad|ipod|ios/i)) {
      return true
    } else {
      return false
    }
  }, [ua])

  const handleDown = useCallback(
    (link: string) => {
      // const newWindow = window.open('_blank')
      if (link !== '/') {
        if (isWx) {
          setShowBg(true)
        } else {
          const a = document.createElement('a')
          a.href = link
          a.id = 'open'
          a.target = '_blank'
          document.body.appendChild(a)
          document.getElementById('open')?.click()
          // newWindow.location.href = link
          // window.open(link)
        }
      } else {
        return
      }
    },
    [isWx]
  )

  return (
    <DownloadWrap>
      <ContainerWrap>
        <LogoWrap>
          <img src={require('../../assets/images/kanga-logo.png')} alt="" srcSet="" />
        </LogoWrap>
        <TitleWrap>
          <div className={'title'}>Kanga</div>
          <div className="text">Simple and user-friendly DeFi wallet</div>
        </TitleWrap>

        {isIos ? (
          <DownloadBtn>
            {isWx ? (
              <>
                <div
                  className={'btn'}
                  onClick={() => {
                    handleDown(downLink.ios)
                  }}
                >
                  <img src={require('../../assets/images/down_ios.png')} alt="" srcSet="" />
                  APP Store
                </div>
                <div
                  className={'btn'}
                  onClick={() => {
                    handleDown(downLink.google)
                  }}
                >
                  <img src={require('../../assets/images/down_google.png')} alt="" srcSet="" />
                  Google Play
                </div>
              </>
            ) : (
              <>
                <a className={'btn'} href={downLink.ios} target={'_blank'} rel="noopener noreferrer">
                  <img src={require('../../assets/images/down_ios.png')} alt="" srcSet="" />
                  APP Store
                </a>
                <a className={'btn'} href={downLink.google} target={'_blank'} rel="noopener noreferrer">
                  <img src={require('../../assets/images/down_google.png')} alt="" srcSet="" />
                  Google Play
                </a>
              </>
            )}
          </DownloadBtn>
        ) : (
          <DownloadBtn>
            {isWx ? (
              <>
                <div
                  className={'btn'}
                  onClick={() => {
                    handleDown(downLink.andriod)
                  }}
                >
                  <img src={require('../../assets/images/down_andriod.png')} alt="" srcSet="" />
                  Andriod Apk
                </div>
                <div
                  className={'btn'}
                  onClick={() => {
                    handleDown(downLink.google)
                  }}
                >
                  <img src={require('../../assets/images/down_google.png')} alt="" srcSet="" />
                  Google Play
                </div>
              </>
            ) : (
              <>
                <div
                  className={'btn'}
                  onClick={() => {
                    window.open(downLink.andriod)
                  }}
                >
                  <img src={require('../../assets/images/down_andriod.png')} alt="" srcSet="" />
                  Andriod Apk
                </div>
                <a className={'btn'} href={downLink.google} target={'_blank'} rel="noopener noreferrer">
                  <img src={require('../../assets/images/down_google.png')} alt="" srcSet="" />
                  Google Play
                </a>
              </>
            )}
          </DownloadBtn>
        )}
        <Container>
          <img src={require('../../assets/images/kanga-app.png')} alt="" srcSet="" />
        </Container>
      </ContainerWrap>
      {showBg && (
        <DownloadBg>
          <img src={require('../../assets/images/kanga-bg.png')} alt="" srcSet="" />
        </DownloadBg>
      )}
    </DownloadWrap>
  )
}
