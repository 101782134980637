import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import enTrans from "../lang/en.json";
import zhTrans from "../lang/zh.json";
import { initReactI18next } from 'react-i18next';
 
i18n.use(LanguageDetector) //获取当前浏览器语言
.use(initReactI18next) 
.init({
  //资源文件
  resources: {
    en: {
      translation: enTrans,
    },
    zh: {
      translation: zhTrans,
    },
  },
  fallbackLng: "en",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
})
 
export default i18n;