import React, { useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import Header from '../../layout/components/Header'
import Footer from '../../layout/components/Footer'

import { AppBody, AppHeadWrap, HeadContainer, HeadTitle, AppSectionWrap, SectionContainer, SectionText } from './styles'

import { downLink } from '../../config/links'

export default function Home() {
  const { t } = useTranslation()
  const ua = window.navigator.userAgent.toLowerCase()
  const isIos = useMemo(() => {
    if (ua.match(/iphone|ipad|ipod|ios/i)) {
      return true
    } else {
      return false
    }
  }, [ua])
  return (
    <AppBody>
      <Header home={true} />
      <AppHeadWrap>
        <HeadContainer>
          <HeadTitle>
            <div className="title">{t('homeTitle')}</div>
            <div className="text">{t('homeTitleText')}</div>
            <div className="download">
              {isMobile ? (
                <div className="download-link">
                  {isIos ? (
                    <a href={downLink.ios} target="_blank" className={'item'} rel="noopener noreferrer">
                      <img src={require('../../assets/images/down_ios.png')} alt="" srcSet="" />
                      App Store
                    </a>
                  ) : (
                    <a href={downLink.andriod} target="_blank" className={'item'} rel="noopener noreferrer">
                      <img src={require('../../assets/images/down_andriod.png')} alt="" srcSet="" />
                      Andriod
                    </a>
                  )}

                  <a href={downLink.google} target="_blank" className={'item'} rel="noopener noreferrer">
                    <img src={require('../../assets/images/down_google.png')} alt="" srcSet="" />
                    Google Play
                  </a>
                </div>
              ) : (
                <div className="download-link">
                  <a href={downLink.ios} target="_blank" className={'item'} rel="noopener noreferrer">
                    <img src={require('../../assets/images/down_ios.png')} alt="" srcSet="" />
                    App Store
                  </a>

                  <a href={downLink.google} target="_blank" className={'item'} rel="noopener noreferrer">
                    <img src={require('../../assets/images/down_google.png')} alt="" srcSet="" />
                    Google Play
                  </a>
                  <a href={downLink.andriod} target="_blank" className={'item'} rel="noopener noreferrer">
                    <img src={require('../../assets/images/down_andriod.png')} alt="" srcSet="" />
                    Andriod
                  </a>
                </div>
              )}

              <div className="download-qr">
                <img src={require('../../assets/images/qr_icon.png')} alt="" srcSet="" className={'icon'} />
                <div className="qrcode">
                  <img src={require('../../assets/images/qr_img.png')} alt="" srcSet="" />
                </div>
              </div>
            </div>
          </HeadTitle>
          <div className="">
            {isMobile ? (
              <img src={require('../../assets/images/head_img_m.png')} className={'head-img'} alt="" srcSet="" />
            ) : (
              <img src={require('../../assets/images/head_img.png')} className={'head-img'} alt="" srcSet="" />
            )}
          </div>
        </HeadContainer>
      </AppHeadWrap>

      <AppSectionWrap>
        <SectionContainer>
          <div>
            <img src={require('../../assets/images/wallet_1.png')} alt="" srcSet="" width={'736px'} />
          </div>
          <SectionText>
            <div className="title">{t('homeMultiWallet')}</div>
            <div className="text">{t('homeMultiWalletText')}</div>
            <div className="minor-text">
              <p>{t('homeMultiWalletText1')}</p>
              <p>{t('homeMultiWalletText2')}</p>
              <p>{t('homeMultiWalletText3')}</p>
            </div>
          </SectionText>
        </SectionContainer>
      </AppSectionWrap>

      <AppSectionWrap bg>
        <SectionContainer>
          <SectionText bg>
            <div className="title">{t('homeExchangeInstantly')}</div>
            <div className="text">{t('homeExchangeInstantlyText')}</div>
            <div className="minor-text">
              <p>{t('homeExchangeInstantlyText1')}</p>
              <p>{t('homeExchangeInstantlyText2')}</p>
              <p>{t('homeExchangeInstantlyText3')}</p>
            </div>
          </SectionText>
          <img src={require('../../assets/images/wallet_2.png')} alt="" srcSet="" width={'658px'} />
        </SectionContainer>
      </AppSectionWrap>

      <AppSectionWrap>
        <SectionContainer>
          <img src={require('../../assets/images/wallet_3.png')} alt="" srcSet="" width={'770px'} />
          <SectionText>
            <div className="title">{t('homeDashboardForDeFi')}</div>
            <div className="text">{t('homeDashboardForDeFiText')}</div>
            <div className="minor-text">
              <p>{t('homeDashboardForDeFiText1')}</p>
              <p>{t('homeDashboardForDeFiText2')}</p>
            </div>
          </SectionText>
        </SectionContainer>
      </AppSectionWrap>

      <AppSectionWrap bg>
        <SectionContainer>
          <SectionText bg>
            <div className="title">{t('homePrivateSecure')}</div>
            <div className="text">{t('homePrivateSecureText')}</div>
            <div className="minor-text">
              <p>{t('homePrivateSecureText1')}</p>
              <p>{t('homePrivateSecureText2')}</p>
            </div>
          </SectionText>
          <img src={require('../../assets/images/wallet_4.png')} alt="" srcSet="" width={'730px'} />
        </SectionContainer>
      </AppSectionWrap>

      <AppSectionWrap>
        <SectionContainer>
          <img src={require('../../assets/images/wallet_5.png')} alt="" srcSet="" width={'600px'} />
          <SectionText>
            <div className="title">{t('homeDapp')}</div>
            <div className="text">{t('homeDappText')}</div>
            <div className="minor-text">
              <p>{t('homeDappText1')}</p>
              <p>{t('homeDappText2')}</p>
            </div>
          </SectionText>
        </SectionContainer>
      </AppSectionWrap>

      <Footer />
    </AppBody>
  )
}
