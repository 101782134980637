import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import {
  LayoutHeader,
  HeaderWrap,
  StyledLogoLink,
  HeaderLogo,
  HeaderContainer,
  HeaderLinks,
  StyledNavLink,
  HeaderMobileContainer,
  HeadLang,
  LangList,
  LangItem
} from '../styles'

const allLanguages = [
  { code: 'en', language: 'English', label: 'EN' },
  { code: 'zh-CN', language: '简体中文', label: 'ZH' }
]

export default function Header({ home = false }: { home?: boolean }) {
  const { t, i18n } = useTranslation()
  const [showMenu, setShowMenu] = useState(false)
  const [showHeader, setShowHeader] = useState(false)
  const refPrevOffset = useRef(window.pageYOffset)
  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset
      // const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight
      // const isTopOfPage = currentOffset === 0
      if (currentOffset >= 100) {
        setShowHeader(true)
      } else {
        setShowHeader(false)
      }

      refPrevOffset.current = currentOffset
    }
    // const throttledHandleScroll = throttle(handleScroll, 200)

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [showHeader])

  const showBg = useMemo(() => {
    if (isMobile) {
      return true
    } else {
      if (home && !showHeader) {
        return false
      } else {
        return true
      }
    }
  }, [home, showHeader])

  const handleChangeLang = useCallback(
    lang => {
      i18n.changeLanguage(lang.code)
    },
    [i18n]
  )

  return (
    <LayoutHeader bg={showBg}>
      <HeaderWrap>
        <StyledLogoLink to={'/'}>
          <HeaderLogo>
            {showBg ? (
              <img src={require('../../assets/svg/logo.svg')} alt="" srcSet="" className={'logo'} />
            ) : (
              <img src={require('../../assets/svg/logo_white.svg')} alt="" srcSet="" className={'logo'} />
            )}
          </HeaderLogo>
        </StyledLogoLink>

        <HeaderContainer>
          <HeaderLinks bg={showBg}>
            <StyledNavLink id={`home-nav-link`} exact strict to={'/'}>
              {t('home')}
            </StyledNavLink>

            <StyledNavLink id={`privacy-nav-link`} to={'/privacy'}>
              {t('privacy')}
            </StyledNavLink>
            <StyledNavLink id={`terms-nav-link`} to={'/terms'}>
              {t('terms')}
            </StyledNavLink>
            <StyledNavLink id={`help-nav-link`} to={'/help'}>
              {t('help')}
            </StyledNavLink>
          </HeaderLinks>

          <HeadLang bg={showBg}>
            <div className={'lang-active'}>
              <span>{i18n.language.toUpperCase()}</span>
              {showBg ? (
                <img src={require('../../assets/images/lang_icon_black.png')} alt="" srcSet="" />
              ) : (
                <img src={require('../../assets/images/lang_icon_white.png')} alt="" srcSet="" />
              )}
            </div>
            <div className="lang-arrow"></div>
            <LangList className={'lang-list'}>
              {allLanguages.map(lang => {
                return (
                  <LangItem key={lang.code} active={lang.code === i18n.language} onClick={() => handleChangeLang(lang)}>
                    {lang.language}
                  </LangItem>
                )
              })}
            </LangList>
          </HeadLang>
        </HeaderContainer>
        <div className="mobile-menu">
          <HeadLang bg={true}>
            <div className={'lang-active'}>
              <span>{i18n.language.toUpperCase()}</span>
              {showBg ? (
                <img src={require('../../assets/images/lang_icon_black.png')} alt="" srcSet="" />
              ) : (
                <img src={require('../../assets/images/lang_icon_white.png')} alt="" srcSet="" />
              )}
            </div>
            <div className="lang-arrow"></div>
            <LangList className={'lang-list'}>
              {allLanguages.map(lang => {
                return (
                  <LangItem key={lang.code} active={lang.code === i18n.language} onClick={() => handleChangeLang(lang)}>
                    {lang.language}
                  </LangItem>
                )
              })}
            </LangList>
          </HeadLang>
          <div
            className="menu"
            onClick={() => {
              setShowMenu(!showMenu)
            }}
          >
            <img src={require('../../assets/images/menu.png')} alt="" srcSet="" />
          </div>
        </div>

        {showMenu && (
          <HeaderMobileContainer>
            <div
              className="bg"
              onClick={() => {
                setShowMenu(!showMenu)
              }}
            ></div>
            <HeaderLinks bg={true}>
              <StyledNavLink id={`home-nav-link`} exact strict to={'/'}>
                {t('home')}
              </StyledNavLink>

              <StyledNavLink id={`privacy-nav-link`} to={'/privacy'}>
                {t('privacy')}
              </StyledNavLink>
              <StyledNavLink id={`terms-nav-link`} to={'/terms'}>
                {t('terms')}
              </StyledNavLink>
              <StyledNavLink id={`help-nav-link`} to={'/help'}>
                {t('help')}
              </StyledNavLink>
            </HeaderLinks>
          </HeaderMobileContainer>
        )}
      </HeaderWrap>
    </LayoutHeader>
  )
}
